.sponsors-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.sponsor-card {
    width: 250px;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px var(--grayishBlue);
    padding: 30px;
    margin-top:2rem;
    /* margin: 20px;   */
}
.sponsor-card img {
    width:80%;
    max-height: 300px;
}
.sponsor-card.hai {
    border-top: 5px solid #1591cd;
}
.sponsor-card.eurobank {
    border-top: 5px solid #EA002A;
}
.sponsor-card.altair {
    border-top: 5px solid #fa4616;
}
.sponsor-card.wurth {
    border-top: 5px solid #c00;
}
.sponsor-card.avek {
    border-top: 5px solid #212B3D;
}
.sponsor-card.pitsirikos {
    border-top: 5px solid #ED262E;
}
.sponsor-card.pyrforos {
    border-top: 5px solid #000;
}
.sponsor-card.efood {
    border-top: 5px solid #ED2E2E;
}
.sponsor-card.bestrcshops {
    border-top: 5px solid #396578;
}
.sponsor-card.hellenic-drones {
    border-top: 5px solid #E40D0D;
}
.sponsor-card.bb-accounting {
    border-top: 5px solid #CAAE6E;
}
.sponsor-card.fibermax {
    border-top: 5px solid #040048;
}
.sponsor-card.global {
    border-top: 5px solid #027CC7;
}
.sponsor-card.elval {
    border-top: 5px solid #77787b;
}
.sponsor-card.mantopoulos {
    border-top: 5px solid #231f20;
}
.sponsor-card.nax {
    border-top: 5px solid #E50106;
}
.sponsor-card.airport {
    border-top: 5px solid #0056a2;
}
.sponsor-card.kaoussis {
    border-top: 5px solid #000;
}
.sponsor-card.motor-oil {
    border-top: 5px solid #E3051B;
}
.sponsor-card.hellasdigital {
    border-top: 5px solid #0796DD;
}
@media screen and (max-width:900px) {
    .sponsor-card {
        width: 250px;
    }
}