.sponsors-container {
    padding: 7rem 6rem 0rem 6rem;
}
.sponsors-category {
    margin-block: 4rem;
}
.sponsors-category-title {
    font-size: clamp(3rem, 3vw, 5rem);
    /* text-align: center; */
    /* margin: 0 auto; */
}
.sponsors-category-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;
    justify-content: center;
}
@media screen and (max-width:1150px) {
    .sponsors-container {
         padding: 8rem 2rem;
    }
}

@media screen and (max-width:960px) {
    .sponsors-container {
         padding:8rem 1.5rem;
    }
}

@media screen and (max-width:730px) {
    .sponsors-container {
         padding:8rem 0.5rem;
    }
}

@media screen and (max-width:650px) {
    .sponsors-container {
         padding:8rem 0.5rem;
    }
}

@media screen and (max-width:500px) {
    .sponsors-container {
         padding:8rem 0.5rem;
    }
}