 
  .skeleton-card {
    /* display: flex; */
    /* flex-direction: column; */
    /* flex-basis: 300px; */
    /* flex-shrink: 0; */
    /* flex-grow: 0; */
    width: 90%;
    margin: 2rem auto;
    background-color: #FFF;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    overflow: hidden;
    max-height: 500px;
  }
  
  .skeleton-card-img {
    padding-bottom: 56.25%;
    position: relative;
  }
  .skeleton-card-img img {
    /* position: absolute; */
    width: 100%;
  }
  
  .skeleton {
    background-color: #e2e5e7;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left 0 top 0;
    -webkit-animation: shine 1s ease infinite;
            animation: shine 1s ease infinite;
  }
  
  @-webkit-keyframes shine {
    to {
      background-position: right 0 top 0;
    }
  }
  
  @keyframes shine {
    to {
      background-position: right 0 top 0;
    }
  }