/* .preloader {
    height:100%;
    width:100%;
    background-color:#333333;
    position: absolute;
    z-index: 100000000;
    margin:0;
    padding:0;
}

.preloader-container{
    height: 300px;
    width: 300px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.preloader-container img {
     width:90%;
     max-width: 300px;
     height: auto;
     top:-200px;
     left:10%;
     margin: 0 auto;;
     position: relative;
}

.moon{
    background-color: #39beff;
    height: 170px;
    width: 170px;
    border-radius: 50%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
}

.crater{
    background-color: #31b4ff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    position: relative;
}

.crater:before{
    content: "";
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    box-shadow: -5px 0 0 2px #1ca4f9;
    top: 2px;
    left: 7px;
}

.crater-1{
    top: 27px;
    left: 90px;
    transform: scale(0.9);
}

.crater-2{
    bottom: 15px;
    left: 61px;
    transform: scale(0.6);
}

.crater-3{
    left: 15px;
    transform: scale(0.75);
}

.crater-4{
    left: 107px;
    top: 32px;
    transform: scale(1.18);
}

.crater-5{
    left: 33px;
    bottom: 4px;
    transform: scale(0.65);
}

.shadow{
    height: 190px;
    width: 190px;
    box-shadow: 21px 0 0 5px rgba(0,0,0,0.15);
    border-radius: 50%;
    position: relative;
    bottom: 157.5px;
    right: 46px;
}

.eye{
    height: 12px;
    width: 12px;
    background-color: #161616;
    position: relative;
    border-radius: 50%;
}

.eye-l{
    bottom: 255px;
    left: 59px;
}

.eye-r{
    bottom: 267px;
    left: 101px;
}

.mouth{
    height: 5px;
    width: 10px;
    border: 3px solid #161616;
    position: relative;
    bottom: 262px;
    left: 79px;
    border-top: none;
    border-radius: 0 0 10px 10px;
}

.blush{
    height: 7.5px;
    width: 7.5px;
    background-color: #1ca4f9;
    position: relative;
    border-radius: 50%;
}

.blush-1{
    bottom: 273px;
    left: 50px;
}

.blush-2{
    bottom: 281px;
    left: 115px;
}

.orbit{
    height: 280px;
    width: 280px;
    border-radius: 50%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    animation: spin 5s infinite linear;
}

@keyframes spin{
    100%{
        transform: rotate(360deg);
    }
}

.rocket{
    background-color: #fafcf7;
    height: 50px;
    width: 25px;
    border-radius: 50% 50% 0 0;
    position: relative;
    left: -11px;
    top: 115px;
}

.rocket:before{
    position: absolute;
    content: "";
    background-color: #39beff;
    height: 20px;
    width: 55px;
    z-index: -1;
    border-radius: 50% 50% 0 0;
    right: -15px;
    bottom: 0;
}

.rocket:after{
    position: absolute;
    content: "";
    background-color: #39beff;
    height: 4px;
    width: 15px;
    border-radius: 0 0 2px 2px;
    bottom: -4px;
    left: 4.3px;
}

.window{
    height: 10px;
    width: 10px;
    background-color: #151845;
    border: 2px solid #b8d2ec;
    border-radius: 50%;
    position: relative;
    top: 17px;
    left: 5px;
} */

.preloader-container{
    background-color: #333333;
    position: absolute;
    z-index: 99999;
    height: 100vh;
    width: 100%;

}
.preloader-container img {
    width: 300px;
    position: relative;
    top:30%;
    left: 50%;
    transform: translate(-50%,-50%);
    
}
.loader {
    position: absolute;
    top: 50%;
    left: 40%;
    margin-left: 10%;
    transform: translate3d(-50%, -50%, 0);
  }
  .dot {
    width: 24px;
    height: 24px;
    background: #112D4E;
    border-radius: 100%;
    display: inline-block;
    animation: slide 1s infinite;
  }
  .dot:nth-child(1) {
    animation-delay: 0.1s;
    background: #112D4E;
  }
  .dot:nth-child(2) {
    animation-delay: 0.2s;
    background: #3F72AF;
  }
  .dot:nth-child(3) {
    animation-delay: 0.3s;
    background: #9BA4B4;
  }
  .dot:nth-child(4) {
    animation-delay: 0.4s;
    background: #DBE2EF;
  }
  .dot:nth-child(5) {
    animation-delay: 0.5s;
    background: #F9F7F7;
  }
  
  @-moz-keyframes slide {
    0% {
      transform: scale(1);
    }
    50% {
      opacity: 0.3;
      transform: scale(2);
    }
    100% {
      transform: scale(1);
    }
  }
  @-webkit-keyframes slide {
    0% {
      transform: scale(1);
    }
    50% {
      opacity: 0.3;
      transform: scale(2);
    }
    100% {
      transform: scale(1);
    }
  }
  @-o-keyframes slide {
    0% {
      transform: scale(1);
    }
    50% {
      opacity: 0.3;
      transform: scale(2);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes slide {
    0% {
      transform: scale(1);
    }
    50% {
      opacity: 0.3;
      transform: scale(2);
    }
    100% {
      transform: scale(1);
    }
  }