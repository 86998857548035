.new {
    padding: 7rem 6rem 2rem 6rem;
    /* width: 100%; */
    font-weight: 500;
}
.new-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;

}
.new-image {
    width: 100%;
    height: auto;
    /* height: 100%; */
    /* height:470px; */
    /* height: 100%; */
    /* background-color: #eee; */
    /* background-repeat: no-repeat; */
    /* background-size: contain; */
    /* background-position: center; */
    /* background-position-x:0; */
    /* margin: 0 auto; */
    border-radius: 4px;
    overflow: hidden;    
    /* box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px; */
}
.new-content {
    margin: 0 auto;
    width: 100%;
}
.new-content .title {
    background-color: #003366;
    color:#fff;
    padding: 0.2rem 0.5rem;
    font-size: 1.7rem;
    border-radius: 4px;
    width: fit-content;
}
.new-content .date {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: gray;
}
.new-content .date img {
    width: 17px;
    margin-right: 5px;
}
.new-content .body {
    font-size: 1.125rem;
    line-height: 1.4rem;
    text-align: justify;
}
.new-container .new-content .body h1 {
    text-align: initial!important;
    color: #003366!important;
    padding:0.3rem 0;
}
.new-container .new-content .body h2 {
    text-align: initial!important;
    color: #003366!important;
    padding:0.3rem 0;
}
.new-container .new-content .body h3 {
    text-align: initial!important;
    color: #003366!important;

    padding:0.3rem 0;
}
.new-container .new-content .body h4 {
    text-align: initial!important;
    padding:0.3rem 0;
    color: #003366!important;
}
.new-container .new-content .body h5 {
    text-align: initial!important;
    color: #003366!important;
    padding:0.3rem 0;
}
.new-container .new-content .body h1 span {
    text-align: initial!important;
    color: #003366!important;
    padding:0.3rem 0;
}
.new-container .new-content .body h2 span {
    text-align: initial!important;
    color: #003366!important;
    padding:0.3rem 0;
}
.new-container .new-content .body h3 span {
    text-align: initial!important;
    color: #003366!important;

    padding:0.3rem 0;
}
.new-container .new-content .body h4 span {
    text-align: initial!important;
    padding:0.3rem 0;
    color: #003366!important;
}
.new-container .new-content .body h5 span {
    text-align: initial!important;
    color: #003366!important;
    padding:0.3rem 0;
}
.new-content .body li {
    list-style-type:disc!important;
}
.new-content .body img {
    width: 95%!important;
    height: auto!important;
    border-radius: 4px;
}
.new-content .body div {
    width: 100%!important;
    height: auto!important;
}
.new-content .body figure figure img {
    width: 95%!important;
}
.new-content .body figure  {
    margin:0;
    padding:0;
}
.new-content .body iframe {
    width: 95%!important;
    height: auto!important;
}
.new-content video {
    width: 95%!important;
    height: auto!important;
}
@media screen and (max-width:1250px) {
    .new-container {
        width: 70%;
    }
    .new-content .body {
        font-size: 1.1rem;
    }
}

@media screen and (max-width:790px) {
    .new {
        padding: 7rem 1.5rem 2rem 1.5rem;
    }
}

@media screen and (max-width:590px) {
    .new {
        padding: 7rem 1rem 2rem 1rem;

    }
    .new-container {
        width: 90%;
    }
    .new-image {
        /* height:325px; */
    }
    .new-content .title {
        font-size: 1.5rem;
    }
    .new-content .body {
        font-size: 1rem;
        line-height: 1.2rem;
    }
    .new-content .body ul {
        margin:0;
        padding:0 0 0 25px;
    }
}

@media screen and (max-height:710px) and (min-width:900px) {
    .new-image {
        width: 100%;
        /* height:400px;  */
    }
}