section.section {
    padding: 2rem 3rem;
    font-weight: 500;
}
  
  section.section .title {
    font-size: 3rem;
    font-weight: 600;
    background-color: #003366;
    color:#fff;
    width: fit-content;
    padding: 2px 15px 2px 5px;
    border-radius: 2px;
    font-style: italic;
    position: relative;
  }
  
  section.section .title::before {
    content: "";
    width:50px;
    height:50px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top:-50px;
    left:0;
    margin:0;
    padding:0;
  }
  section.section.who-we-are .title::before {
    opacity: 0.6;
    background-image: url(../images/airplane.svg);
  }
  section.section.activities .title::before {
    opacity: 0.6;
    /* background-image: url(https://cdn-icons-png.flaticon.com/512/947/947680.png); */
    background-image: url(../images/landing-rover-optimized.webp);
  }
  section.section.targets .title::before {
    opacity: 0.6;
    background-image: url(../images/landing-target-optimized.webp);
  }
  section.section.departments .title::before {
    background-image: url(../images/landing-departments.svg);
  }
  section.section.sponsors .title::before {
    background-image: url(../images/sponsors-optimized.webp);
    width: 150px;
  }
  section.section .text {
    font-size: 1.125rem;
    line-height: 1.7rem;
    padding: 0 1.5rem;
    text-align: justify;
  }
  section.section.dark {
    background-color: #003366;
  }
  
  section.section.dark .title {
    color:#F1F6F9;
  }
  
  section.section.dark .text {
    color:#F1F6F9;
  }
  section.section .departments {
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-around;
}
section.section .departments .department {
    width: 20%;
    height:250px;
    margin:1.5rem;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

section.section .departments .department .department-title {
    font-size: 1.2rem;
    text-align: center;   
}
section.section .departments .department .department-description {
    padding: 0 0.7rem;
    line-height: 1.4rem;
}
.skew-c{
    width:100%;
    height:100px;
    left:0px;
    background: linear-gradient(to left bottom, hsl(0, 0%, 98%) 49%, #003366 50%);
}
.skew-cc{
    width:100%;
    height:100px;
    left:0px;
    background: linear-gradient(to right bottom, #003366 49%, hsl(0, 0%, 98%) 50%), linear-gradient(-50deg, hsl(0, 0%, 98%) 16px, #000 0);
}
@media screen and (max-width:730px) {
    section.section {
        padding: 2rem 2rem;
    }
    section.section .title {
        font-size: 2rem;
    }
}
@media screen and (max-width:600px) {
    section.section {
        padding: 2rem 1rem;
    }
    section.section .text {
        font-size: 1.1rem;
        line-height: 1.4rem;
      }
}

@media screen and (max-width:500px) {
    section.section {
        padding: 2rem 0.5rem;
    }
    section.section .text {
        font-size: 1rem;
        line-height: 1.3rem;
      }
}
