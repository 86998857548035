.metrics {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    font-weight: 500;
}
.metrics-card {
    padding: 2rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.metrics-card .metrics-card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}
.metrics-card .metrics-card-header .number {
    font-size: 1.4rem;
}
.metrics-card .metrics-card-header img {
    width: 3rem;
    margin: 0 5px 0 0;
}


@media screen and (max-width:470px) {
    .metrics {
         font-size: 0.9rem;
    }
    .metrics-card .metrics-card-header .number {
        font-size: 1.2rem;
    }
    .metrics-card .metrics-card-header img {
        width: 2.5rem;
    }
}


