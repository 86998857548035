.news-card {
    width:20%;
    /* height:500px; */
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    font-weight: 500;
}

.news-card .image {
    background-repeat:  no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 250px;

}

.news-card .content {
    padding: 1rem 1.125rem;
}

.news-card .content .title {
    text-align: center;
    padding: 0;
    margin: 5px 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.news-card .content hr {
    margin: 0 auto;
    width: 60%;
    border: 2px solid #003366;
    background-color: #003366;
    border-radius: 8px;
}

.news-card .content .text {
    line-height: 1.1rem;
    font-size: 0.9rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.news-card .content .date {
    color:gray;
    display: flex;
    flex-direction: row;
    align-items: center;

}
.news-card .content .date img {
    width: 15px;
    height:auto;
    margin-right: 5px;
}

.news-card .content .view-more {
    margin: 0 auto;
    text-align: center;
    background-color: #003366;
    padding: 0.2rem 0.5rem;
    width: fit-content;
    border-radius: 4px;
    margin-top: 12px;
    position: absolute;
    bottom:10px;
    right: 10px;

}
.news-card .content .view-more a {
    color:#fff;
    text-decoration: none;

}
