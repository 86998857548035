.hero {
    height:100vh;
    width:100%;
    display: flex;
    align-items: center;
}
.bg {
    /* background: url(https://i.ibb.co/87GbbFP/2799006.jpg) no-repeat;  */
    background: url(../images/hero-background-optimized.webp) no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
}
.bg:before {
    content: "";
    width: 100%;
    height: 100vh;
    background: #000;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0.3;
}
@keyframes sf-fly-by-1 {
    from {
        transform: translateZ(-600px);
        opacity: 0.5;
    }
    to {
        transform: translateZ(0);
        opacity: 0.5;
    }
}
@keyframes sf-fly-by-2 {
    from {
        transform: translateZ(-1200px);
        opacity: 0.5;
    }
    to {
        transform: translateZ(-600px);
        opacity: 0.5;
    }
}
@keyframes sf-fly-by-3 {
    from {
        transform: translateZ(-1800px);
        opacity: 0.5;
    }
    to {
        transform: translateZ(-1200px);
        opacity: 0.5;
    }
}
.star-field {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    perspective: 600px;
    -webkit-perspective: 600px;
    z-index: -1;
}
.star-field.animated .layer {
    box-shadow: -411px -476px #cccccc, 777px -407px #d4d4d4, -387px -477px #fcfcfc, -91px -235px #d4d4d4, 491px -460px #f7f7f7, 892px -128px #f7f7f7, 758px -277px #ededed, 596px 378px #cccccc, 647px 423px whitesmoke, 183px 389px #c7c7c7,
        524px -237px #f0f0f0, 679px -535px #e3e3e3, 158px 399px #ededed, 157px 249px #ededed, 81px -450px #ebebeb, 719px -360px #c2c2c2, -499px 473px #e8e8e8, -158px -349px #d4d4d4, 870px -134px #cfcfcf, 446px 404px #c2c2c2,
        440px 490px #d4d4d4, 414px 507px #e6e6e6, -12px 246px #fcfcfc, -384px 369px #e3e3e3, 641px -413px #fcfcfc, 822px 516px #dbdbdb, 449px 132px #c2c2c2, 727px 146px #f7f7f7, -315px -488px #e6e6e6, 952px -70px #e3e3e3,
        -869px -29px #dbdbdb, 502px 80px #dedede, 764px 342px #e0e0e0, -150px -380px #dbdbdb, 654px -426px #e3e3e3, -325px -263px #c2c2c2, 755px -447px #c7c7c7, 729px -177px #c2c2c2, -682px -391px #e6e6e6, 554px -176px #ededed,
        -85px -428px #d9d9d9, 714px 55px #e8e8e8, 359px -285px #cfcfcf, -362px -508px #dedede, 468px -265px #fcfcfc, 74px -500px #c7c7c7, -514px 383px #dbdbdb, 730px -92px #cfcfcf, -112px 287px #c9c9c9, -853px 79px #d6d6d6,
        828px 475px #d6d6d6, -681px 13px #fafafa, -176px 209px #f0f0f0, 758px 457px #fafafa, -383px -454px #ededed, 813px 179px #d1d1d1, 608px 98px whitesmoke, -860px -65px #c4c4c4, -572px 272px #f7f7f7, 459px 533px #fcfcfc,
        624px -481px #e6e6e6, 790px 477px #dedede, 731px -403px #ededed, 70px -534px #cccccc, -23px 510px #cfcfcf, -652px -237px whitesmoke, -690px 367px #d1d1d1, 810px 536px #d1d1d1, 774px 293px #c9c9c9, -362px 97px #c2c2c2,
        563px 47px #dedede, 313px 475px #e0e0e0, 839px -491px #e3e3e3, -217px 377px #d4d4d4, -581px 239px #c2c2c2, -857px 72px #cccccc, -23px 340px #dedede, -837px 246px white, 170px -502px #cfcfcf, 822px -443px #e0e0e0, 795px 497px #e0e0e0,
        -814px -337px #cfcfcf, 206px -339px #f2f2f2, -779px 108px #e6e6e6, 808px 2px #d4d4d4, 665px 41px #d4d4d4, -564px 64px #cccccc, -380px 74px #cfcfcf, -369px -60px #f7f7f7, 47px -495px #e3e3e3, -383px 368px #f7f7f7, 419px 288px #d1d1d1,
        -598px -50px #c2c2c2, -833px 187px #c4c4c4, 378px 325px whitesmoke, -703px 375px #d6d6d6, 392px 520px #d9d9d9, -492px -60px #c4c4c4, 759px 288px #ebebeb, 98px -412px #c4c4c4, -911px -277px #c9c9c9;
    transform-style: preserve-3d;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 4px;
    width: 4px;
    border-radius: 2px;
}
.star-field .layer:nth-child(1) {
    animation: sf-fly-by-1 5s linear infinite;
}
.star-field .layer:nth-child(2) {
    animation: sf-fly-by-2 5s linear infinite;
}
.star-field .layer:nth-child(3) {
    animation: sf-fly-by-3 5s linear infinite;
}

.hero .context {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 6rem;
}

.hero .context .text {
    color: #fff;
    display: flex;
    flex-direction: column;
    /* padding-left: 6rem; */
    font-weight: 800;
    width: 100%;
}

.hero .context .text .welcome {
    margin-bottom:-15px;
}

.hero .context .text .title {
    font-size: 5rem;
    margin:0 0 0 -5px;
    padding:0;
}
.greek-flag {
    width: 70px;
    border-radius: 4px;
    /* margin-right: 10px; */
}
/* .hero .context .text h1  {
    background:url(../images/landingPage/greekflag3-1.webp);
    background-size: cover;
    background-position: 0% 50%;
    background-repeat: no-repeat;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

} */
  
.hero .context .text .quote {
    width:60%;
    font-weight: 900;
    margin-top: -5px;
    font-style: italic;
}
.hero .context .text .join-us {
    padding: 7px 12px;
    background-color: #fff;
    color: #003366;
    font-size: 1.125rem;
    width: fit-content;
    text-decoration: none;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.hero .context .text .join-us img {
    width:25px;
}
.pyrforos-wrapper {
    background-color: rgba(255,255,255,0.5);
    max-width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:80%;
    border-radius: 4px;
    margin-top:10px;
    padding:0.5rem;
    color:#000;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.pyrforos-wrapper h3 {
    margin: 0 auto;
    text-align: center;
}
.pyrforos {
    width: 70px;
    height:70px;
    /* margin-right: 10px; */
    border-radius: 50%;
    /* background-color: #fff; */
    /* background-color: rgba(255,255,255,0.8); */
}

@media screen and (max-width:1628px) {
    .hero .context .text .title {
        font-size: 4.5rem;
    }
}

@media screen and (max-width:1250px) {
    .hero .context {
      padding: 0 2.5rem;
    }
    .hero .context .text .title {
        /* font-size: 4.5rem; */
        font-size: 4rem;
    }
}

@media screen and (max-width:1100px) {
    .hero .context .text .welcome {
        margin-bottom:-10px;
    }
    .hero .context .text .title {
        font-size: 3.5rem;
    }
    .hero .context .text .quote {
        width: 80%;
        margin-top:-2px;
    }
}

@media screen and (max-width:1100px) and (max-height:550px) {
    .hero .context .text .title {
        font-size: 3rem;
    }
}

@media screen and (max-width:980px) and (min-height:600px){
    .bg {
        background-image: url(../images/hero-background-optimized-mobile.webp);
    }
    .hero .context {
        flex-direction: column;
        /* align-items: flex-start; */
        margin: 0 auto;
        padding-top:5rem;
        /* margin-top:8rem; */

    }
    .hero .context .text .title {
        font-size: 3.5rem;
    }
}


@media screen and (max-width:590px) {
    .hero .context {
        padding: 1.5rem;
        margin-top:6rem;
      }
      
    .hero .context .text {
        width: 100%;
    }
    .hero .context .text .welcome {
        margin-bottom:-7px;
        margin-left: -2px;
    }
    .hero .context .text .title {
        font-size: 3rem;
    }
    .pyrforos-wrapper h3 {
        font-size: 0.9rem;
    }
    
}

@media screen and (max-width:470px) {
    .hero .context {
        padding: 1.5rem;
        margin-top:6rem;
      }

    .hero .context .text {
        width: 100%;
    }
    .hero .context .text .welcome {
        font-size: 0.9rem;
    }
    .hero .context .text .title {
        font-size: 2.5rem;
    }
    .hero .context .text .quote {
        font-size: 0.95rem;
        width:100%;
    }
}

@media screen and (max-width:430px) {
    .hero .context {
        padding: 0.5rem;
        margin-top: 6rem;
    }
    .hero .context .text .welcome {
        margin-bottom:2px;
    }

    .hero .context .text .title {
        font-size: 2.4rem;
    }
    .hero .context .text .quote {
        margin-top:5px;
    }

}