.navBar .logo img {
float: left;
max-height: 120px;
}
.active {
color: #003366!important;
text-decoration: underline!important;
}

.navBar .col-md-3 {
z-index: 7;
}
ul.main-menu {
display: inline-flex;
list-style: none;
margin: 0px auto 0;
padding: 0;
float: right;
z-index: 7;
}
ul.main-menu>li.menu-item:first-child {
padding-left: 0;
margin-left: 0;
}
.menu-item {
line-height: 23px;
}
li {
list-style-type: none;
}
.menu-item a {
font-size: 17px;
font-weight: 700;
line-height: 40px;
position: relative;
display: block;
color: #545050;
margin:12px 17px;
text-decoration: none;
cursor: pointer;
}
/* .menu-item ::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #003366;
  transform-origin: bottom right;
  transition: transform 0.4s ease-out;
} 

.menu-item :hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
} */
.header__middle__logo{
width: 20%;
display: inline-block;
float: left;
padding:0 2rem;
background-color: #fff;
}
.navbar-logo {
  /* width: 100px; */
  width: 75px; 
  /* --------------------------------------------------------------------- */
}
.header__middle__menus {
width: 100%;
display: inline-block;
float: left;
background-color: #fff;
padding:0 2rem;
}
.menu-item.menu__qqqitem a{
/* margin: 27px 10px; */
margin: 12px 10px;
}
.header__middle__search {
width: 7%;
display: inline-block;
float: right;
padding: 36px 3px 27px 3px;
/* border-left: 1px solid lavender; */
}
.header__middle {
display: flex;
width: 100%;
float: left;
/* border-bottom: 1px dashed lavender; */
position: absolute;
background-color: #fff;
align-items: center;
box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
z-index:1000;
}
.header__middle__logo > a > h1 {
color: #003366;
text-transform: uppercase;
text-decoration: none;
margin-top: 33px;
}
/* .active{
color: #ff1800!important;
} */
.header__middle__menus span.text {
position: absolute;
right: 177px;
bottom: 10px;
font-size: 14px;
color: #ff1800;
}
.sub__menus__arrows{
position: relative;
}
.sub__menus__arrows img, .sub__menus__arrows__full img{
padding-top: 0px;
position: absolute;
top: 13px;
right: -20px;
}
.sub__menus {
position: absolute;
display: none;
background: #fff;
/* border: 1px solid lavender; */
width: 300px;
/* left:-100px; */
left: -120px;
padding: 2px 0 0 0;
z-index: 1000;
box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}

@media (max-width:767px) { 
.header__middle .active {color: #003366!important;}
}

@media (min-width:992px) { 
.sub__menus__arrows:hover .sub__menus{
  display: block;
}
.sub__menus__arrows__full:hover .sub__menus{
  display: block;
  z-index: 1000000;
}
}
.menu-item .sub__menus  a {
padding: 7px 0 0px 0;
margin: 7px 27px;
}
.menu-item:hover > a{
color: #003366 !important;
}
.menu-item .sub__menus li:hover a{
color: #003366 !important;
}

.header__middle__logo img{
max-width: 207px;
/* margin: 17px 0 0 0; */
}


@media(max-width:991px){
  .header__middle__logo {width: 77%;}
  /* .header__middle__menus {width: 20%;} */
  .header__middle__logo img {max-width: 197px;}
  .main-nav .menubar__button:hover {background-color: #003366;}
  .main-nav .menu-item:hover > a {color: #003366 !important}
  .main-nav .menubar__button {
  /* display: block!important; */
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  background-color: #080808;
  color: #fff;
  padding: 6px 7px;
  border-radius: 5px;
  /* margin: 27px auto 0; */
  cursor: pointer;
  position: relative;
  z-index: 10037;
  }
  .main-nav .menubar__button svg{font-size: 27px;}
  .main-nav ul.menuq2 {
  display: block!important;
  position: absolute;
  /* top:83.16px; */
  top:63px;
  left: 0;
  z-index: 10007;
  background-color: #fff;
  border-bottom: 3px solid #003366;
  right: 0;
  padding-bottom: 17px;
  padding-top: 57px;
  height: 100vh;
  }
  .main-nav .menu-item a {
    /* margin: 10px 17px; */
    margin: 10px auto;
    width: fit-content;
    text-align: center;
    color: #000000;}
  .main-nav  ul.main-menu {display: none}
  .main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
  .sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
  .sub__menus__Active2{display: block!important;}
  .menus__categorysss{display: none;}
  .menus__categorysss2{display: none;}
  .menu-item .sub__menus__full a {text-align: left;}

}