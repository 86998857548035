.newsletter {
    /* height:250px; */
    /* width: 95%; */
    max-width: 400px;
    border-radius: 4px;
    /* margin:2rem auto; */
}

.top-border {
    background-color: #003366;
    color:#fff;
    border-radius: 4px;
}
.newsletter-body img {
    /* width: 80%; */
    /* margin-top: 1rem; */
    width: 25px;
    margin-left: 5px;
    height: auto;
}
.newsletter a {
    padding:.3rem;
    /* margin-top: 2rem; */
    border-radius: 2px;
    background-color: #fff;
    text-decoration: none;
    color: #003366;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    justify-content: center;
}
.newsletter a:hover {
    /* color: #fff; */
    /* background-color: #003366; */
    /* transition: all 250ms ease-in; */
    border: 1px solid #fff;
    background-color: #003366;
    color: #fff;
    transition: all 0.2s ease-in;
}

.newsletter a:hover > img {
    /* color: #fff; */
    /* background-color: #003366; */
    /* transition: all 250ms ease-in; */
   
   background-color:#fff;
}
