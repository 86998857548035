section.activities .swiper {
    width: 95%;
    height: 450px;
}

section.activities .swiper-slide {
    text-align: center;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    z-index: 2;
    color:#fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    /* -webkit-box-pack: center; */
    /* -ms-flex-pack: center; */
    /* -webkit-justify-content: center; */
    /* justify-content: center; */
    /* -webkit-box-align: center; */
    /* -ms-flex-align: center; */
    /* -webkit-align-items: center; */
    /* align-items: center; */
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px;
}
   
section.activities .swiper-text {
    color: #000;
    text-align: left;
    padding: 1rem 1rem 0rem 1rem;
    line-height: 1.3rem;
    /* text-align: justify; */

}
section.activities .swiper-slide img {
    display: block;
    width: 100%;
    height: 60%;
    max-height: 250px;
    /* position: absolute; */
    /* top:0; */
    object-fit: cover;
    z-index: -1;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 8px;

}
/* .swiper-pagination-bullet {
    background-color: #fff!important;
    opacity:0.7!important;
}
.swiper-pagination-bullet-active {
    background-color: #DD3782!important;
} */