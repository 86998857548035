html {
  margin:0;
  padding:0;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: 'Ubuntu', sans-serif; */
  font-family: 'Roboto', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  font-weight: 500;
}

/* strong {
  color:#cf42c1;
} */
/* ::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #407dba;
}
::-webkit-scrollbar-thumb {
  background-color: #407dba;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #407dba;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #003366;
} */