.fancyborder-image {
    width: 70%;
    /* padding-right: 6rem; */
  }
  .container{  
    padding-top: 20px;
    padding-bottom: 20px;
    width:100%;
  }

  .background-img {
    /* background-image: url("https://content.fortune.com/wp-content/uploads/2020/05/SpaceX-NASA-Launch-04.jpg"); */
    background-image: url(../images/fancyborder-optimized.webp);
    height: 400px;
    /* width: 800px; */
    /* width:60%; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* margin: 5% auto; */
    /* padding:20px; */
    border: 1px solid #2a3cad;
    border-radius: 4px;
    box-shadow: 0px 0px 5px #2a3cad;
    position: relative;
  }
  
  .content h2{ font-size:19px;}
  
  .box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 300px;
    background: #111845a6;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0 20px 50px rgb(23, 32, 90);
    border: 2px solid #2a3cad;
    color: #fff;
    padding: 20px;
  }
  
  .box:before{
    content: '';
    position:absolute;
    top:0;
    left:-100%;
    width:100%;
    height:100%;
    background: rgba(255,255,255,0.1);
    transition:0.5s;
    pointer-events: none;
  }
  
  .box:hover:before{
    left:-50%;
    transform: skewX(-5deg);
  }
  
  
  .box .content{
    position:absolute;
    top:15px;
    left:15px;
    right:15px;
    bottom:15px;
    border:1px solid #f0a591;
    padding:20px;
    text-align:center;
    box-shadow: 0 5px 10px rgba(9,0,0,0.5);
  }
  
  .box span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
    
  }
  
  .box span:nth-child(1)
  {
    transform:rotate(0deg);
    -ms-transform: translate(0deg); /* IE 9 */
    -webkit-transform: translate(0deg); /* Safari */
  }
  
  .box span:nth-child(2)
  {
    transform:rotate(90deg);
    -ms-transform: translate(90deg); /* IE 9 */
    -webkit-transform: translate(90deg); /* Safari */
  }
  
  .box span:nth-child(3)
  {
    transform:rotate(180deg);
    -ms-transform: translate(180deg); /* IE 9 */
    -webkit-transform: translate(180deg); /* Safari */
  }
  
  .box span:nth-child(4)
  {
    transform:rotate(270deg);
    -ms-transform: translate(270deg); /* IE 9 */
    -webkit-transform: translate(270deg); /* Safari */
  }
  
  .box span:before
  {
    content: '';
    position: absolute;
    width:100%;
    height: 2px;
    background: #50dfdb;
    animation: animate 4s linear infinite;

  }

  .neonText.animated {
    color: #fff;
    text-shadow:
      0 0 7px #50dfdb,
      0 0 10px #50dfdb,
      0 0 21px #50dfdb,
      0 0 42px #50dfdb,
      0 0 82px #50dfdb,
      0 0 92px #50dfdb,
      0 0 102px #50dfdb,
      0 0 151px #50dfdb;
}

  
  @keyframes animate {
    0% {
    transform:scaleX(0);
    transform-origin: left;
    }
    50%
    {
      transform:scaleX(1);
    transform-origin: left;
    }
    50.1%
    {
      transform:scaleX(1);
    transform-origin: right;
      
    }
    
    100%
    {
      transform:scaleX(0);
    transform-origin: right;
      
    }
  }

  @media screen and (max-width:980px) {
    .background-img {
      background-image: url(../images/fancyborder-optimized-mobile.webp);
    }
    .fancyborder-image {
        width:70%;
        margin-top:2rem;
    }
}

@media screen and (max-width:720px) {
  .fancyborder-image {
      width:90%;
      margin-top:2rem;
  }
}

@media screen and (max-width:450px) {
    .background-img {
        height:300px;
    }
    .box {
        height: 250px;
        width: 250px;
    }
    .content h1{
        font-size: 1.4rem;
    }
}

@media screen and (max-height:780px) {
  .background-img {
      height:300px;
  }
  .box {
      height: 250px;
      width: 250px;
  }
  .content h1{
      font-size: 1.4rem;
  }
}